<script>
  import { fade } from 'svelte/transition';
  import Select, { Option } from '@smui/select';
  import ContentRow from '../ContentRow.svelte';

  export let hmdJson;

  const fadeConfig = {
    duration: 200,
  };
</script>

{#if hmdJson?.scenarioName}
  <ContentRow class="session-content-panel">
    <div class="session-content-panel__scenario-name" in:fade|local={fadeConfig}>
      <Select class="gigxr-input" value={hmdJson.scenarioName} variant="filled" label="Experiment" enhanced disabled>
        <Option value={hmdJson.scenarioName}>{hmdJson.scenarioName}</Option>
      </Select>
    </div>
  </ContentRow>
{:else}
  <p>No content available.</p>
{/if}

<style>
</style>

<script>
  import { beforeUpdate, onMount, createEventDispatcher } from 'svelte';
  import { navigate } from 'svelte-routing';
  import { NON_BREAKING_SPACE } from '../../util/constants.js';
  import { utcStringToLocalDate } from '../../util/dates';
  import { clientApps } from '../../stores/client-apps-store';
  import Card, { Content, ActionIcons } from '@smui/card';
  import IconButton, { Icon } from '@smui/icon-button';
  import Menu from '@smui/menu';
  import List, { Item, Separator, Text } from '@smui/list';
  import Ripple from '@smui/ripple';
  import {
    archiveSession,
    unarchiveSession,
    archiveSessionPlan,
    unarchiveSessionPlan,
    copySession,
    createSessionPlanFromSession,
  } from '../../util/api/sessions';
  import { deepCopy } from '../../util/util.js';
  import { snackbar, snackbarMessage } from '../../stores/core-store';
  import { getAccountId, getAccountRole } from '../../util/account';
  import GigXrDialog from '../gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogContent from '../gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogIcon from '../gigxr-dialog/GigXrDialogIcon.svelte';
  import GigXrDialogActions from '../gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogCancelButton from '../gigxr-dialog/GigXrDialogCancelButton.svelte';
  import GigXrDialogButton from '../gigxr-dialog/GigXrDialogButton.svelte';

  export let session = null;
  export let compact = false;
  export let selectedValues;

  let visible = false;
  let instructorName = '';
  let clientAppName = '';
  let clientAppVersion = '';
  let archiveDialog;
  let archivePlanDialog;
  let saveAsDialog;
  let saveAsSessionName = session.sessionName;

  let clientAppsById = new Map();
  $: canEditSession = session.createdById === getAccountId();

  let isGigXrAdmin = getAccountRole() === 'GigXrAdmin';


  const dispatch = createEventDispatcher();

  beforeUpdate(async () => {
    $clientApps.forEach((app) => clientAppsById.set(app.clientAppId, app.clientAppName));

    if (session && session.instructorId && session.instructor) {
      instructorName = `${session.instructor.firstName} ${session.instructor.lastName}`;
    }

    if (session && session.clientAppVersion) {
      clientAppVersion = session.clientAppVersion;
    } else {
      clientAppVersion = ''; // resetting clientAppVersion to nothing in case the value lingers from last iteration
    }

    // cleanup
    if (clientAppVersion === null || clientAppVersion === 'null') {
      clientAppVersion = '';
    } 

    if (session) {
      if (clientAppsById.has(session.clientAppId)) {
        clientAppName = clientAppsById.get(session.clientAppId);
      }
    }
  });

  function toggleMenu() {
    visible = !visible;
  }

  let sessionMenu;

  function cardClickHandler() {
    if (compact) {
      // Checkboxes are shown, so a click event should click the corresponding row's checkbox.
      const set = new Set(selectedValues);
      if (set.has(session.sessionId)) {
        set.delete(session.sessionId);
      } else {
        set.add(session.sessionId);
      }
      selectedValues = [...set];

      return;
    }

    if (session.sessionPlan) {
      navigate(`/sessions/plans/view/${session.sessionId}`);
    } else {
      navigate(`/sessions/view/${session.sessionId}`);
    }
  }

  async function saveSessionAs() {
    console.log(session, session.sessionName);
    const newSession = await copySession(deepCopy(session), saveAsSessionName);
    snackbarMessage.set('Session saved!');
    $snackbar.open();

    navigate(`/sessions/view/${newSession.sessionId}`);
  }

  async function addToSessionPlans() {
    const sessionPlan = await createSessionPlanFromSession(session.sessionId);
    dispatch('GigXr:SessionCard:addToSessionPlan');
    snackbarMessage.set('Added to session plans!');
    $snackbar.open();
  }

  async function archiveSessionHandler() {
    await archiveSession(session.sessionId);
    session.sessionStatus = 'Archived';
    snackbarMessage.set('Session archived!');
    $snackbar.open();
  }

  async function unarchiveSessionHandler() {
    await unarchiveSession(session.sessionId);
    session.sessionStatus = 'Ended';
    snackbarMessage.set('Session unarchived!');
    $snackbar.open();
  }

  async function archiveSessionPlanHandler() {
    await archiveSessionPlan(session.sessionId);
    session.sessionStatus = 'Archived';
    snackbarMessage.set('Session plan archived!');
    $snackbar.open();
  }

  async function unarchiveSessionPlanHandler() {
    await unarchiveSessionPlan(session.sessionId);
    session.sessionStatus = 'Ended';
    snackbarMessage.set('Session plan unarchived!');
    $snackbar.open();
  }

  function toggleSessionArchiveStatusHandler() {
    if (session.sessionStatus === 'Archived') {
      unarchiveSessionHandler();
    } else {
      archiveDialog.open();
    }
  }

  function toggleSessionPlanArchiveStatusHandler() {
    if (session.sessionStatus === 'Archived') {
      unarchiveSessionPlanHandler();
    } else {
      archivePlanDialog.open();
    }
  }
</script>

<div class="card-container {compact ? 'card-container--compact' : ''}">
  <Card class="gigxr-card">
    <Content class={session.sessionStatus === 'Archived' ? 'session-archived' : ''}>
      {#if session.gigXrBranded}
        <div class="gigxr-branded-session-plan" />
      {/if}
      <div
        id="session-card-{session.sessionId}"
        class="card-content"
        on:click={cardClickHandler}
        use:Ripple={{ ripple: true, color: 'surface' }}
      >
        <div class="app-image">
          <div class="app-image__outer-circle">
            {#if clientAppName === 'HoloPatient'}
              <img class="app-image__icon" src="/icons/holopatient.svg" alt="HoloPatient Icon" />
            {:else if clientAppName === 'HoloHuman'}
              <img class="app-image__icon" src="/icons/holohuman.svg" alt="HoloHuman Icon" />
            {:else if clientAppName === 'HoloChem' || clientAppName === 'HoloChemLab'}
              <img class="app-image__icon" src="/icons/holochemlab.svg" alt="HoloChem Icon" />
            {:else}<img class="app-image__icon" src="/icons/gigxr-logo-white.svg" alt="GIGXR Session Logo" />{/if}
          </div>
          <div class="app-image__text">{clientAppName}
            {#if clientAppVersion !== ''}
            <br/>ver: <span>{clientAppVersion}</span>
            {:else}
            <br />
            {/if}
          </div>
        </div>
        <ul class="card-details">
          <li class="card-details__item session-name" title={session.sessionName}>{session.sessionName}</li>
          {#if !session.sessionPlan}
            <li class="card-details__item">{utcStringToLocalDate(session.lessonDate).toLocaleString()}</li>
          {/if}
          <li class="card-details__item">{session.classEntity ? session.classEntity.className : NON_BREAKING_SPACE}</li>
          <li class="card-details__item">{instructorName ? instructorName : NON_BREAKING_SPACE}</li>
          {#if session.sessionPlan}
            <li class="card-details__item">{NON_BREAKING_SPACE}</li>
          {/if}
        </ul>
        {#if !session.sessionPlan && session.locked}
          <Icon class="gigxr-card__lock material-icons">lock</Icon>
        {/if}
      </div>
      {#if session.sessionStatus === 'InProgress' && !session.sessionPlan}
        <div class="qr-code" use:Ripple={{ ripple: true, color: 'surface' }}>
          <div class="qr-code__label">In Progress</div>
        </div>
      {/if}
      {#if session.sessionPlan}
        <div class="session-label">Plan</div>
      {:else if session.invited}
        <div class="session-label {session.sessionStatus === 'InProgress' ? 'session-label--offset' : ''}">Invited</div>
      {/if}
    </Content>
    {#if !isGigXrAdmin}
    <ActionIcons>
      <div>
        <IconButton
          id="session-card-button-{session.sessionId}"
          class="material-icons"
          title="More options"
          on:click={sessionMenu.setOpen(true)}
        >
          more_vert
        </IconButton>
        <Menu bind:this={sessionMenu} anchorCorner="BOTTOM_LEFT" class="gigxr-menu">
          {#if session.sessionPlan}
            <List>
              <Item
                id="session-plan-card-menu-create-{session.sessionId}"
                on:SMUI:action={() => navigate(`/sessions/create?fromPlanId=${session.sessionId}`)}
              >
                <Text>Create New Session</Text>
              </Item>
              <Item
                id="session-plan-card-menu-view-{session.sessionId}"
                on:SMUI:action={() => navigate(`/sessions/plans/view/${session.sessionId}`)}
              >
                <Text>View Session Plan</Text>
              </Item>
              {#if canEditSession}
                <Item
                  id="session-plan-card-menu-archive-{session.sessionId}"
                  on:SMUI:action={() => toggleSessionPlanArchiveStatusHandler()}
                >
                  <Text>{session.sessionStatus === 'Archived' ? 'Unarchive' : 'Archive'}</Text>
                </Item>
              {/if}
            </List>
          {:else}
            <List>
              <Item
                id="session-card-menu-view-{session.sessionId}"
                on:SMUI:action={() => navigate(`/sessions/view/${session.sessionId}`)}
              >
                <Text>View</Text>
              </Item>
              <Item id="session-card-menu-save-as-{session.sessionId}" on:SMUI:action={() => saveAsDialog.open()}>
                <Text>Save As</Text>
              </Item>
              <Item
                id="session-card-menu-add-session-plans-{session.sessionId}"
                on:SMUI:action={() => addToSessionPlans()}
              >
                <Text>Add to Session Plans</Text>
              </Item>
              {#if canEditSession}
                <Item
                  id="session-card-menu-archive-{session.sessionId}"
                  on:SMUI:action={() => toggleSessionArchiveStatusHandler()}
                >
                  <Text>{session.sessionStatus === 'Archived' ? 'Unarchive' : 'Archive'}</Text>
                </Item>
              {/if}
            </List>
          {/if}
        </Menu>
      </div>
    </ActionIcons>
    {/if}
  </Card>
</div>

<GigXrDialog bind:dialog={saveAsDialog} ariaPrefix="session-save-as">
  <GigXrDialogContent>
    <GigXrDialogIcon />
    <p>Enter new session name</p>
    <input id="save-session-as-field" type="text" class="gigxr-input" bind:value={saveAsSessionName} />
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton id="save-session-as-cancel-button">Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton id="save-session-as-button" on:click={saveSessionAs}>Save As</GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<GigXrDialog bind:dialog={archiveDialog} ariaPrefix="archive-session">
  <GigXrDialogContent>
    <GigXrDialogIcon />
    Are you sure you want to archive this session?
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton id="archive-session-cancel-button">Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton id="archive-session-button" on:click={archiveSessionHandler}>Archive</GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<GigXrDialog bind:dialog={archivePlanDialog} ariaPrefix="archive-session-plan">
  <GigXrDialogContent>
    <GigXrDialogIcon />
    Are you sure you want to archive this session plan?
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton id="archive-session-plan-cancel-button">Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton id="archive-session-plan-button" on:click={archiveSessionPlanHandler}>Archive</GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<style>
  :global(.session-archived) {
    opacity: 0.5;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin-bottom: 0.2em;
  }

  .session-name {
    font-weight: bold;
    font-size: 18px;
  }

  .card-container {
    width: 303px;
    font-size: 13px;
    font-family: 'Barlow', sans-serif;
    margin-bottom: 30px;
  }

  .card-container--compact {
    width: 250px;
  }

  .card-content {
    display: flex;
    padding: 16px;
    border-radius: 10px;
  }

  .card-content:hover {
    cursor: pointer;
  }

  :global(.card-container .mdc-card__action-icons) {
    display: inline-block;
    margin-left: auto;
  }

  .card-details {
    margin-left: 12px;
    padding-top: 5px;
  }

  .card-details__item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 135px;
  }

  :global(.gigxr-card) {
    border-radius: 10px !important;
    position: relative;
  }

  :global(.gigxr-card .mdc-card__action-icons) {
    margin-top: -50px;
    position: relative;
  }

  :global(.gigxr-card .smui-card__content) {
    padding: 0;
  }

  .app-image {
    border-right: thin solid #d2dce2;
    padding-right: 12px;
    padding-top: 5px;
    text-align: center;
  }

  .app-image__outer-circle {
    display: inline-block;
    border: 1px solid #31373c;
    padding: 3px;
    border-radius: 50%;
  }

  .app-image__icon {
    width: 50px;
    height: 50px;
    background: #31373c;
    border-radius: 50%;
  }

  .app-image__text {
    text-transform: uppercase;
    font-size: 9px;
    margin-top: 9px;
  }

  .qr-code {
    position: absolute;
    right: 0;
    top: 9px;
    background: #fabe6e;
    border-radius: 5px 0 0 5px;
    text-align: center;
  }

  .qr-code__label {
    background: var(--gigxr-theme-secondary-3c);
    text-transform: lowercase;
    color: #fff;
    font-size: 10px;
    padding: 1px 4px 1px 6px;
    border-radius: 0 0 0 5px;
  }

  .session-label {
    position: absolute;
    right: 12px;
    top: 8px;
    text-transform: uppercase;
    color: #94aabb;
    font-size: 12px !important;
  }

  .session-label--offset {
    right: 65px;
  }

  .gigxr-branded-session-plan {
    background-image: url('/assets/gigxr-banner-cards.png');
    background-repeat: no-repeat;
    background-size: 50px;
    background-position: left bottom;
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 1;
  }

  :global(.gigxr-card .gigxr-card__lock) {
    color: var(--gigxr-theme-primary-1a);
    position: absolute;
    top: 6px;
    left: 6px;
    font-size: 18px;
  }
</style>

<script>
  import { title, breadcrumbPaths } from '../stores/core-store';
  import PrimaryContent from '../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../components/SecondaryBackgroundWrapper.svelte';

  title.set('Support');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Support',
      location: '/support',
    },
  ]);
</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent><span>Please contact your institution admin for support.</span></PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
</style>

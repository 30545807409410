<script>
  import { onMount } from 'svelte';
  import { link } from 'svelte-routing';
  import { role, title, errorMessage, breadcrumbPaths, snackbar, snackbarMessage } from '../../stores/core-store';
  import { slide } from 'svelte/transition';
  import { getAccountId } from '../../util/account';
  import { authenticatedPost } from '../../util/api.js';
  import { navigate } from 'svelte-routing';
  import { roundMinutesToNearestFive, utcStringToLocalDate } from '../../util/dates';
  import Tab, { Icon, Label } from '@smui/tab';
  import TabBar from '@smui/tab-bar';
  import Button from '@smui/button';
  import Textfield from '@smui/textfield';
  import Select, { Option } from '@smui/select';
  import PrimaryContent from '../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import TwoColumnSection from '../../components/TwoColumnSection.svelte';
  import ContentRow from '../../components/ContentRow.svelte';
  import ContentRowCenteredOnMobile from '../../components/ContentRowCenteredOnMobile.svelte';
  import DateAccountComponent from '../../components/DateAccountComponent.svelte';
  import { fetchClientApps, fetchLicensedClientApps } from '../../util/api/client-apps';
  import { fetchClasses } from '../../util/api/classes';
  import { fetchInstructors } from '../../util/api/accounts';
  import FilledTextArea from '../../components/FilledTextArea.svelte';
  import Datepicker from 'svelte-calendar';
  import {
    createSession,
    createSessionPlan,
    editSession,
    fetchSessionPlan,
    getSessionPermissions,
  } from '../../util/api/sessions';
  import LoadingView from '../../components/LoadingView.svelte';
  import JoinSessionButton from '../../components/sessions/JoinSessionButton.svelte';
  import ClientAppLogo from '../../components/client-apps/ClientAppLogo.svelte';
  import { getQueryParameter } from '../../util/util';
  import { SessionPermission, SessionPermissionText } from '../../util/api/sessions';
  import SessionPermissionSelect from '../../components/sessions/SessionPermissionSelect.svelte';
  import GigXrDatepicker from '../../components/GigXrDatepicker.svelte';
  import GigXrTimepicker from '../../components/GigXrTimepicker.svelte';
  import SessionLockSwitch from '../../components/sessions/SessionLockSwitch.svelte';
  import DiscardChangesDialog from '../../components/DiscardChangesDialog.svelte';

  title.set('Create Session');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Session List',
      location: '/sessions/list',
    },
    {
      name: 'Create Session',
      location: `/sessions/create`,
    },
  ]);

  let tabs = ['Details'];
  let clientAppVersion;
  let licensedClientApps = [];
  let classesById = {};
  let instructorsById = {};

  let discardChangesDialog;

  let formattedSelected;
  let dateValue;
  let hourValue;
  let minuteValue;

  let fromPlanId = null;

  const date = new Date();
  date.setSeconds(0);
  date.setMilliseconds(0);
  let todayIso = date.toISOString();
  todayIso = todayIso.substring(0, todayIso.length - 1);

  let lastSavedSession = {
    sessionName: '',
    clientAppVersion: '',
    clientAppId: '',
    classId: '',
    instructorId: '',
    lessonDate: todayIso,
    description: '',
    sessionPermission: 'Private',
    saved: true,
    locked: false,
  };

  if ($role === 'Instructor') {
    lastSavedSession.instructorId = getAccountId();
  }

  let session = null;

  $: {
    if (session) {
      const newDate = dateValue;
      newDate.setHours(hourValue, minuteValue);
      session.lessonDate = newDate.toISOString();
    }
  }

  let clientAppName = null;
  $: {
    if (session) {
      clientAppName = licensedClientApps
        .filter((app) => app.clientAppId === session.clientAppId)
        .map((app) => app.clientAppName)
        .join('');
    }
  }

  onMount(async () => {
    let classes;
    let instructors;
    [licensedClientApps, classes, instructors] = await Promise.all([
      fetchLicensedClientApps(),
      fetchClasses(),
      fetchInstructors(),
    ]);

    classes.forEach((clazz) => (classesById[clazz.classId] = clazz));
    instructors.forEach((i) => (instructorsById[i.accountId] = i));

    fromPlanId = getQueryParameter('fromPlanId');
    if (fromPlanId) {
      const sessionPlan = await fetchSessionPlan(fromPlanId);
      // see if we have a clientAppVersion
      if (typeof sessionPlan.clientAppVersion != 'undefined' && sessionPlan.clientAppVersion != 'null') {
        lastSavedSession.clientAppVersion = sessionPlan.clientAppVersion;
      } else {
        lastSavedSession.clientAppVersion = '';
      }
      lastSavedSession.sessionName = sessionPlan.sessionName;
      lastSavedSession.clientAppId = sessionPlan.clientAppId;
      lastSavedSession.classId = sessionPlan.classId;
      lastSavedSession.instructorId = sessionPlan.instructorId;
      lastSavedSession.description = sessionPlan.description;
      lastSavedSession.sessionPermission = sessionPlan.sessionPermission;
      lastSavedSession.hmdJson = sessionPlan.hmdJson;
      title.set('Create Session From Session Plan');
      errorMessage.set('You are creating a new Session from the selected Session Plan');
    }

    session = { ...lastSavedSession };
    loadSessionToUi();
  });

  function loadSessionToUi() {

    if (typeof session.clientAppVersion != 'undefined' && typeof session.clientAppVersion != 'null') {
      clientAppVersion = session.clientAppVersion;
    } else {
      clientAppVersion = '';
    }

    dateValue = utcStringToLocalDate(session.lessonDate);
    hourValue = String(dateValue.getHours());
    minuteValue = String(roundMinutesToNearestFive(dateValue.getMinutes())).padStart(2, '0');
  }

  function discardChanges() {
    session = { ...lastSavedSession };
    loadSessionToUi();
  }

  async function save() {
    const newSession = await createSession(session);
    snackbarMessage.set('Session created!');
    $snackbar.open();
    navigate(`/sessions/view/${newSession.sessionId}`);
  }

  async function saveAndAddToSessionPlans() {

    const newSession = await createSession(session);
    
    const sessionPlan = await createSessionPlan(newSession,newSession.sessionId);
    snackbarMessage.set('Session created and added to session plans!');
    $snackbar.open();
    navigate(`/sessions/view/${newSession.sessionId}`);
  }
</script>

<PrimaryContent>
  <div class="join-session-button-wrapper">
    <JoinSessionButton session={null} disabled={true} />
  </div>

  <TabBar {tabs} let:tab class="gigxr-tabs">
    <Tab {tab} minWidth>
      <Label>{tab}</Label>
    </Tab>
  </TabBar>
</PrimaryContent>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if session}
      <TwoColumnSection>
        <div slot="left">
          <div class="logo-row">
            <div class="logo-row__logo">
              <ClientAppLogo {clientAppName} />
              <input type="hidden" name="clientAppVersion" value="{clientAppVersion}" disabled="disabled" />
            </div>
            <div class="logo-row__content">
              <ContentRow>
                <Textfield
                  input$id="session-name-field"
                  class="gigxr-input"
                  bind:value={session.sessionName}
                  variant="filled"
                  label="Session Name"
                />
              </ContentRow>

              <ContentRow>
                <Select
                  inputId="client-app-field"
                  class="gigxr-input"
                  bind:value={session.clientAppId}
                  variant="filled"
                  label="App"
                  enhanced
                  disabled={Boolean(fromPlanId)}
                >
                  <Option value="" />
                  {#each licensedClientApps as app (app.clientAppId)}
                    {#if app.clientAppName != "HoloScenarios" || fromPlanId }
                    <Option value={app.clientAppId} selected={app.clientAppId === session.clientAppId}>
                      {app.clientAppName}
                    </Option>
                      {/if}
                  {/each}
                  {#if licensedClientApps.length == 0}
                    <Option disabled on:click={(event) => event.stopPropagation()}>No Licensed Apps</Option>
                  {/if}
                </Select>
              </ContentRow>

              <ContentRow>
                <Select
                  inputId="class-field"
                  class="gigxr-input"
                  bind:value={session.classId}
                  variant="filled"
                  label="Class"
                  enhanced
                >
                  <Option value="" />
                  {#each Object.values(classesById) as clazz (clazz.classId)}
                    <Option value={clazz.classId}>{clazz.className}</Option>
                  {/each}
                </Select>
              </ContentRow>
            </div>
          </div>

          <ContentRow>
            <Select
              inputId="instructor-field"
              class="gigxr-input"
              bind:value={session.instructorId}
              variant="filled"
              label="Instructor"
              enhanced
            >
              <Option value="" />
              {#each Object.values(instructorsById) as instructor (instructor.accountId)}
                <Option
                  value={instructor.accountId}
                  selected={instructor.accountId === session.instructorId || instructor.accountId === getAccountId()}
                >
                  {instructor.firstName}
                  {instructor.lastName}
                </Option>
              {/each}
            </Select>
          </ContentRow>

          <ContentRow class="session-lesson-row">
            <div class="session-lesson-row__label">Lesson Start Date:</div>
            <GigXrDatepicker id="lesson-start-date-field" bind:dateValue />
          </ContentRow>

          <ContentRow class="session-lesson-row">
            <div class="session-lesson-row__label">Lesson Start Time:</div>
            <GigXrTimepicker bind:hourValue bind:minuteValue />
          </ContentRow>

          <ContentRow>
            <FilledTextArea
              id="session-description-field"
              bind:value={session.description}
              label="Description"
              ariaLabel="session-description"
            />
          </ContentRow>
        </div>

        <div slot="right">
          <SessionLockSwitch bind:session />

          <SessionPermissionSelect bind:session />

          <DateAccountComponent label="Created" utcDateString={session.createdOn} account={session.createdBy} />

          <DateAccountComponent label="Last Saved" utcDateString={null} account={null} show_account={false} />

          <ContentRowCenteredOnMobile>
            <Button id="create-session-save-button" class="gigxr-button" variant="unelevated" on:click={() => save()}>
              <Label>Save Changes</Label>
            </Button>
          </ContentRowCenteredOnMobile>

          <ContentRowCenteredOnMobile>
            <!-- svelte-ignore a11y-missing-attribute -->
            <a
              id="create-session-discard-changes-link"
              class="gigxr-link"
              on:click={() => discardChangesDialog.open()}
            >Discard Changes</a>
          </ContentRowCenteredOnMobile>

          <ContentRowCenteredOnMobile>
            <!-- svelte-ignore a11y-missing-attribute -->
            <a
              id="create-session-save-add-session-plans-link"
              class="gigxr-link"
              on:click={saveAndAddToSessionPlans}
            >Save and Add to Session Plans</a>
          </ContentRowCenteredOnMobile>

          <ContentRowCenteredOnMobile>
            <!-- svelte-ignore a11y-missing-attribute -->
            <a id="create-session-save-as-duplicate-link" class="gigxr-link gigxr-link--disabled">Save as Duplicate</a>
          </ContentRowCenteredOnMobile>

          <ContentRowCenteredOnMobile>
            <!-- svelte-ignore a11y-missing-attribute -->
            <a id="create-session-archive-link" class="gigxr-link gigxr-link--disabled">Archive</a>
          </ContentRowCenteredOnMobile>
        </div>
      </TwoColumnSection>
    {:else}
      <LoadingView />
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<DiscardChangesDialog bind:dialog={discardChangesDialog} clickHandler={() => discardChanges()} />

<style>
  :global(.session-date-button) {
    width: 100%;
    background: var(--gigxr-theme-primary-1c) !important;
  }

  .logo-row__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5em;
  }

  .join-session-button-wrapper {
    text-align: center;
    margin-bottom: 1em;
  }

  @media (min-width: 1100px) {
    .join-session-button-wrapper {
      position: absolute;
      right: 25px;
      bottom: 0;
      z-index: 1;
      margin-bottom: 0;
    }

    .logo-row {
      display: flex;
      justify-content: space-between;
    }

    .logo-row__logo {
      flex: 1;
      justify-content: start;
      margin-right: 1em;
    }

    .logo-row__content {
      flex: 3;
    }
  }

  :global(.session-lesson-row) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  :global(.session-lesson-row) .session-lesson-row__label {
    display: inline-block;
    flex: 1;
    font-weight: 700;
    padding-right: 5px;
  }

  @media (min-width: 1100px) {
    :global(.session-lesson-row) {
      width: 60%;
    }
  }
</style>

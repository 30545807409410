<script>
  import { createEventDispatcher } from 'svelte';
  import Button, { Label, Icon } from '@smui/button';
  import IconButton from '@smui/icon-button';

  export let disabled;

  const dispatch = createEventDispatcher();

  function filterClickHandler(event) {
    dispatch('click', event);
  }
</script>

<!-- Note: this disabled prop seems to work differently than on <Button> -->
<IconButton
  id="mobile-filter-button"
  class="material-icons gigxr-icon filter-button filter-button__mobile"
  on:click={filterClickHandler}
  {disabled}
>
  filter_list
</IconButton>

<Button
  id="desktop-filter-button"
  class="filter-button filter-button__desktop"
  on:click={filterClickHandler}
  {disabled}
>
  <Icon class="material-icons gigxr-icon">filter_list</Icon>
  <Label>Filters</Label>
</Button>

<style>
  :global(.filter-button__desktop) {
    display: none;
  }

  :global(.filter-button:disabled) {
    opacity: 0.38;
  }

  @media (min-width: 1100px) {
    :global(.filter-button__mobile) {
      display: none !important;
    }

    :global(.filter-button__desktop) {
      color: var(--mdc-theme-secondary) !important;
      display: inline-block;
    }
  }
</style>

<script>
  import { slide } from 'svelte/transition';
  import Button, { Label } from '@smui/button';
  import Select, { Option } from '@smui/select';
  import { SortBySet, FilterBySet, FilterByRadioSet, FilterBySelectSet, FilterByCheckboxSet } from '../util/filters';
  import PrimaryContent from './PrimaryContent.svelte';

  /**
   * @type {[*]}
   */
  export let unfilteredList;

  /**
   * @type {[*]}
   */
  export let filteredList = [...unfilteredList];

  /**
   * @type {?SortBySet}
   */
  export let sortBySet;

  /**
   * @type {[FilterBySet]}
   */
  export let filterBySets = [];

  let visible = false;

  $: {
    filteredList = [...unfilteredList];

    if (sortBySet instanceof SortBySet) {
      filteredList = sortBySet.sort(filteredList);
    }

    filterBySets.forEach((filterSet) => {
      if (filterSet instanceof FilterBySet) {
        filteredList = filterSet.filter(filteredList);
      }
    });
  }

  export function open() {
    visible = true;
  }

  export function close() {
    visible = false;
  }

  export function toggle() {
    visible = !visible;
  }

  export function isOpen() {
    return visible;
  }

  let filterPanel;

  function checkForClickingOffFilterPanel(event) {
    if (!visible) {
      return;
    }

    const clickedOff = event.composedPath().every((element) => {
      if (element.classList && element.classList.contains('filter-button')) {
        // Ignore events on the filter button
        return false;
      }

      if (element.classList && element.classList.contains('mdc-menu-surface--open')) {
        // Ignore events on open menus. This is so select dropdowns on the filter panel will not trigger closing the panel.
        return false;
      }

      return element !== filterPanel;
    });

    if (clickedOff) {
      // Prevent links actions
      event.preventDefault();

      // Prevent button actions
      event.stopPropagation();

      close();
    }
  }
</script>

<svelte:body on:click|capture={checkForClickingOffFilterPanel} />

{#if visible}
  <div bind:this={filterPanel} class="filter-panel" transition:slide={{ duration: 200 }}>
    <PrimaryContent>
      {#if sortBySet}
        <div class="filter-row">
          <h3>{sortBySet.description}</h3>
          <ul>
            {#each [...sortBySet.sortOptions.keys()] as sortName (sortName)}
              <li>
                <Button
                  variant={sortBySet.selectedValue === sortName ? 'unelevated' : 'outlined'}
                  on:click={() => (sortBySet.selectedValue = sortName)}
                >
                  <Label>{sortName}</Label>
                </Button>
              </li>
            {/each}
          </ul>
        </div>
      {/if}

      {#each filterBySets as filterSet (filterSet.description)}
        <div class="filter-row">
          <h3>{filterSet ? filterSet.description : ''}</h3>
          {#if filterSet instanceof FilterByRadioSet}
            <ul>
              {#each Object.keys(filterSet.filterOptions) as filterName (filterName)}
                <li>
                  <Button
                    variant={filterSet.selectedValue === filterName ? 'unelevated' : 'outlined'}
                    on:click={() => (filterSet.selectedValue = filterName)}
                  >
                    <Label>{filterName}</Label>
                  </Button>
                </li>
              {/each}
            </ul>
          {:else if filterSet instanceof FilterBySelectSet}
            <Select
              bind:value={filterSet.selectedValue}
              variant="outlined"
              label={filterSet.label}
              enhanced
              disabled={Object.keys(filterSet.filterOptions).length === 1}
            >
              {#each Object.entries(filterSet.filterOptions) as [id, value] (id)}
                <Option value={id}>
                  {value}
                  {#if id === filterSet.defaultValue}({Object.keys(filterSet.filterOptions).length - 1}){/if}
                </Option>
              {/each}
            </Select>
          {:else if filterSet instanceof FilterByCheckboxSet}
            <Button
              variant={filterSet.selectedValue ? 'unelevated' : 'outlined'}
              on:click={() => (filterSet.selectedValue = !filterSet.selectedValue)}
            >
              <Label>{filterSet.filterOptions.option}</Label>
            </Button>
          {:else}
            <!-- Unsupported FilterBySet -->
          {/if}
        </div>
      {/each}

      <div class="close-button">
        <Button on:click={close}>DONE</Button>
      </div>
    </PrimaryContent>
  </div>
{/if}

<style>
  .filter-panel {
    border-top: 2px solid #31373d;
    padding: 30px 15px;
    background: var(--gigxr-theme-secondary-2e);
    z-index: 7;
    position: absolute;
    width: 100%;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 0.75em;
  }

  ul {
    list-style: none;
    padding: 0;
    margin-top: 0;
    margin-left: -5px;
    margin-right: -5px;
  }

  li {
    display: inline-block;
    margin: 5px;
  }

  .close-button {
    margin-top: -3.5em;
    float: right;
  }

  .filter-row {
    margin-bottom: 1.5em;
  }

  :global(.filter-panel .mdc-select),
  :global(.filter-panel .mdc-button) {
    background-color: inherit;
    border-color: #31373d;
    color: #31373d;
    text-transform: capitalize;
    border-radius: 6px;
    min-width: 208px;
  }

  :global(.filter-panel .mdc-button--unelevated) {
    background-color: #31373d;
    color: #fff;
  }

  :global(.close-button .mdc-button) {
    min-width: auto;
  }

  :global(.filter-panel .mdc-select--disabled) {
    background: initial !important;
  }
</style>

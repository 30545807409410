<script>
  import { navigate } from 'svelte-routing';
  import Button, { Label, Icon } from '@smui/button';
  import Menu from '@smui/menu';
  import List, { Item, Separator, Text } from '@smui/list';

  let sessionMenu;
</script>

<div>
  <Button id="create-session-button" on:click={() => sessionMenu.setOpen(true)} variant="unelevated">
    <Label>Create New Session</Label>
    <Icon class="material-icons">add</Icon>
  </Button>

  <Menu bind:this={sessionMenu} anchorCorner="BOTTOM_LEFT" class="gigxr-menu">
    <List>
      <Item id="create-session-new-link" on:SMUI:action={() => navigate('/sessions/create')}>
        <Text>Create New Session</Text>
      </Item>
      <Item id="create-session-use-plan-link" on:SMUI:action={() => navigate('/sessions/plans/list')}>
        <Text>Use Session Plan</Text>
      </Item>
    </List>
  </Menu>
</div>

<style>
</style>

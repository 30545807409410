<script>
  import Switch from '@smui/switch';
  import KeyValueRow from '../KeyValueRow.svelte';

  export let session;
  export let disabled = false;
</script>

<KeyValueRow>
  <div slot="left">Lock:</div>
  <div slot="right">
    <Switch bind:checked={session.locked} class="gigxr-switch" {disabled} />
  </div>
</KeyValueRow>

<style>
</style>

class SortBySet {
  constructor({ description, defaultValue, sortOptions }) {
    this.description = description;
    this.defaultValue = defaultValue;
    this.sortOptions = sortOptions;
    this.selectedValue = defaultValue;
  }

  /**
   * @param {[*]} array
   * @returns {[*]} A reference to the same array, sorted.
   */
  sort(array) {
    return array.sort(this.sortOptions.get(this.selectedValue));
  }
}

class FilterBySet {
  constructor({ description, defaultValue, filterOptions, filterCallback }) {
    this.description = description;
    this.defaultValue = defaultValue;
    this.filterOptions = filterOptions;
    this.selectedValue = defaultValue;
    this._filterCallback = null;

    if (typeof filterCallback === 'function') {
      this._filterCallback = filterCallback;
    }

    if (this.constructor === FilterBySet) {
      throw new Error('FilterBySet is abstract and cannot be instantiated directly!');
    }
  }

  /**
   * @param {[*]} array
   * @returns {[*]}
   */
  filter(array) {
    if (typeof this._filterCallback === 'function') {
      return this._filterCallback(array, this.selectedValue);
    } else {
      const filterCallback = this.filterOptions[this.selectedValue];

      if (filterCallback) {
        return array.filter(this.filterOptions[this.selectedValue]);
      }
      console.warn('Could not find valid filterCallback!');

      return array;
    }
  }
}

class FilterByRadioSet extends FilterBySet {
  constructor({ description, defaultValue, filterOptions, filterCallback }) {
    super({ description, defaultValue, filterOptions, filterCallback });
  }
}

class FilterBySelectSet extends FilterBySet {
  constructor({ description, defaultValue, filterOptions, filterCallback, label }) {
    super({ description, defaultValue, filterOptions, filterCallback });
    this.label = label;
  }
}

class FilterByCheckboxSet extends FilterBySet {
  constructor({ description, defaultValue, filterOptions, filterCallback }) {
    super({ description, defaultValue, filterOptions, filterCallback });
  }
}

export { SortBySet, FilterBySet, FilterByRadioSet, FilterBySelectSet, FilterByCheckboxSet };

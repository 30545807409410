<script>
  import { onMount } from 'svelte';
  import { link } from 'svelte-routing';
  import { role, title, errorMessage, breadcrumbPaths, snackbar, snackbarMessage } from '../../stores/core-store';
  import { slide } from 'svelte/transition';
  import { getAccountId } from '../../util/account';
  import { authenticatedPost } from '../../util/api.js';
  import { navigate } from 'svelte-routing';
  import { roundMinutesToNearestFive, utcStringToLocalDate } from '../../util/dates';
  import Tab, { Icon, Label } from '@smui/tab';
  import TabBar from '@smui/tab-bar';
  import Button from '@smui/button';
  import Textfield from '@smui/textfield';
  import Select, { Option } from '@smui/select';
  import PrimaryContent from '../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import TwoColumnSection from '../../components/TwoColumnSection.svelte';
  import ContentRow from '../../components/ContentRow.svelte';
  import ContentRowCenteredOnMobile from '../../components/ContentRowCenteredOnMobile.svelte';
  import DateAccountComponent from '../../components/DateAccountComponent.svelte';
  import { fetchClientApps, fetchLicensedClientApps } from '../../util/api/client-apps';
  import { fetchClasses } from '../../util/api/classes';
  import { fetchInstructors } from '../../util/api/accounts';
  import { fetchSessionPlansForVersion } from '../../util/api/sessions';
  import FilledTextArea from '../../components/FilledTextArea.svelte';
  import Datepicker from 'svelte-calendar';
  import {
    createSession,
    createSessionPlan,
    editSession,
    fetchSessionPlan,
    getSessionPermissions,
  } from '../../util/api/sessions';
  import LoadingView from '../../components/LoadingView.svelte';
  import JoinSessionButton from '../../components/sessions/JoinSessionButton.svelte';
  import ClientAppLogo from '../../components/client-apps/ClientAppLogo.svelte';
  import { getQueryParameter } from '../../util/util';
  import { SessionPermission, SessionPermissionText } from '../../util/api/sessions';
  import SessionPermissionSelect from '../../components/sessions/SessionPermissionSelect.svelte';
  import GigXrDatepicker from '../../components/GigXrDatepicker.svelte';
  import GigXrTimepicker from '../../components/GigXrTimepicker.svelte';
  import SessionLockSwitch from '../../components/sessions/SessionLockSwitch.svelte';
  import DiscardChangesDialog from '../../components/DiscardChangesDialog.svelte';

  export let clientAppId;

  title.set('Create Session');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Session List',
      location: '/sessions/list',
    },
    {
      name: 'Create Session',
      location: `/sessions/create`,
    },
  ]);

  let tabs = ['Details'];

  let licensedClientApps = [];
  let classesById = {};
  let instructorsById = {};
  let sessionPlansForVersion = [];
  const latestAppVersion = 'v44';
  let selectedSession = null;

  let discardChangesDialog;

  let formattedSelected;
  let dateValue;
  let hourValue;
  let minuteValue;

  let appId = clientAppId;

  let fromPlanId = null;

  const date = new Date();
  date.setSeconds(0);
  date.setMilliseconds(0);
  let todayIso = date.toISOString();
  todayIso = todayIso.substring(0, todayIso.length - 1);

  let lastSavedSession = {
    sessionName: '',
    clientAppId: '',
    classId: '',
    instructorId: '',
    lessonDate: todayIso,
    description: '',
    sessionPermission: 'Private',
    saved: true,
    locked: false,
  };

  if ($role === 'Instructor') {
    lastSavedSession.instructorId = getAccountId();
  }

  let session = null;

  $: {
    if (session) {
      const newDate = dateValue;
      newDate.setHours(hourValue, minuteValue);
      session.lessonDate = newDate.toISOString();
    }
  }

  let clientAppName = null;
  $: {
    if (session) {
      clientAppName = licensedClientApps
        .filter((app) => app.clientAppId === session.clientAppId)
        .map((app) => app.clientAppName)
        .join('');
    }
  }

  onMount(async () => {
    let classes;
    let instructors;
    [licensedClientApps, classes, instructors, sessionPlansForVersion] = await Promise.all([
      fetchLicensedClientApps(),
      fetchClasses(),
      fetchInstructors(),
      fetchSessionPlansForVersion(clientAppId, latestAppVersion)
    ]);
    console.log(`***** got sessionPlansForVersion: ${JSON.stringify(sessionPlansForVersion)}`)
    classes.forEach((clazz) => (classesById[clazz.classId] = clazz));
    instructors.forEach((i) => (instructorsById[i.accountId] = i));

    fromPlanId = getQueryParameter('fromPlanId');
    if (fromPlanId) {
      const sessionPlan = await fetchSessionPlan(fromPlanId);
      lastSavedSession.sessionName = sessionPlan.sessionPlanName;
      lastSavedSession.clientAppId = sessionPlan.clientAppId;
      lastSavedSession.classId = sessionPlan.classId;
      lastSavedSession.instructorId = sessionPlan.instructorId;
      lastSavedSession.description = sessionPlan.description;
      lastSavedSession.sessionPermission = sessionPlan.sessionPlanPermission;
      lastSavedSession.hmdJson = sessionPlan.hmdJson;
      title.set('Create Session From Session Plan');
      errorMessage.set('You are creating a new Session from the selected Session Plan');
    }
    console.log(`***** clientAppId: ${clientAppId}, appId: ${appId}`);
    session = { ...lastSavedSession };
    session.clientAppId = clientAppId;
    loadSessionToUi();
  });

  function loadSessionToUi() {
    dateValue = utcStringToLocalDate(session.lessonDate);
    hourValue = String(dateValue.getHours());
    minuteValue = String(roundMinutesToNearestFive(dateValue.getMinutes())).padStart(2, '0');
  }

  function discardChanges() {
    session = { ...lastSavedSession };
    loadSessionToUi();
  }

  async function selectPlan() {
    navigate(`/sessions/create?fromPlanId=${selectedSession}`);
  }

  async function saveAndAddToSessionPlans() {
    const newSession = await createSession(session);
    const sessionPlan = await createSessionPlan(newSession);
    snackbarMessage.set('Session created and added to session plans!');
    $snackbar.open();
    navigate(`/sessions/view/${newSession.sessionId}`);
  }
</script>

<PrimaryContent>
  <div class="join-session-button-wrapper">
    <JoinSessionButton session={null} disabled={true} />
  </div>

  <TabBar {tabs} let:tab class="gigxr-tabs">
    <Tab {tab} minWidth>
      <Label>{tab}</Label>
    </Tab>
  </TabBar>
</PrimaryContent>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if session}
      <TwoColumnSection>
        <div slot="left">
          <div class="logo-row">
            <div class="logo-row__logo">
              <ClientAppLogo {clientAppName} />
            </div>
            <div class="logo-row__content">

              <ContentRow>
                <Select
                  inputId="client-app-field"
                  class="gigxr-input"
                  bind:value={session.clientAppId}
                  variant="filled"
                  label="App"
                  enhanced
                  disabled={Boolean(fromPlanId)}
                >
                  <Option value="" />
                  {#each licensedClientApps as app (app.clientAppId)}
                    <Option value={app.clientAppId} selected={app.clientAppId === session.clientAppId}>
                      {app.clientAppName}
                    </Option>
                  {/each}
                  {#if licensedClientApps.length == 0}
                    <Option disabled on:click={(event) => event.stopPropagation()}>No Licensed Apps</Option>
                  {/if}
                </Select>
              </ContentRow>

              <ContentRow>
                <Select
                  inputId="plan-field"
                  class="gigxr-input"
                  bind:value={selectedSession}
                  variant="filled"
                  label="Session Plan"
                  enhanced
                  disabled={Boolean(fromPlanId)}
                >
                  <Option value="" />
                  {#each sessionPlansForVersion as sess }
                    <Option value={sess.sessionId} selected={sess.sessionId === selectedSession}>
                      {sess.sessionName}
                    </Option>
                  {/each}
                </Select>
              </ContentRow>

              <ContentRowCenteredOnMobile>
                <Button id="create-session-save-button" class="gigxr-button" variant="unelevated" on:click={() => selectPlan()}>
                  <Label>Next</Label>
                </Button>
              </ContentRowCenteredOnMobile>
            </div>
          </div>
        </div>

        <div slot="right">

        </div>
      </TwoColumnSection>
    {:else}
      <LoadingView />
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<DiscardChangesDialog bind:dialog={discardChangesDialog} clickHandler={() => discardChanges()} />

<style>
  :global(.session-date-button) {
    width: 100%;
    background: var(--gigxr-theme-primary-1c) !important;
  }

  .logo-row__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5em;
  }

  .join-session-button-wrapper {
    text-align: center;
    margin-bottom: 1em;
  }

  @media (min-width: 1100px) {
    .join-session-button-wrapper {
      position: absolute;
      right: 25px;
      bottom: 0;
      z-index: 1;
      margin-bottom: 0;
    }

    .logo-row {
      display: flex;
      justify-content: space-between;
    }

    .logo-row__logo {
      flex: 1;
      justify-content: start;
      margin-right: 1em;
    }

    .logo-row__content {
      flex: 3;
    }
  }

  :global(.session-lesson-row) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  :global(.session-lesson-row) .session-lesson-row__label {
    display: inline-block;
    flex: 1;
    font-weight: 700;
    padding-right: 5px;
  }

  @media (min-width: 1100px) {
    :global(.session-lesson-row) {
      width: 60%;
    }
  }
</style>

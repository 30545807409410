function utcStringToLocalDate(utcString) {
  if (utcString && !utcString.includes('Z')) {
    utcString = `${utcString}Z`;
  }
  return new Date(utcString);
}

class Iso8601RegExp extends RegExp {
  constructor() {
    // https://stackoverflow.com/questions/3143070/javascript-regex-iso-datetime
    super(
      /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/,
      'g',
    );
  }
}

function replaceUtcStringsWithLocalDate(string) {
  const regExp = new Iso8601RegExp();
  return string.replace(regExp, function (match) {
    return utcStringToLocalDate(match).toLocaleString();
  });
}

function datesEqual(dateA, dateB) {
  return (
    dateA &&
    dateB &&
    dateA.getUTCFullYear() === dateB.getUTCFullYear() &&
    dateA.getUTCDate() === dateB.getUTCDate() &&
    dateA.getUTCDay() === dateB.getUTCDay()
  );
}

function hoursEqual(hour, date) {
  hour = String(hour);

  return hour && date && hour === String(date.getHours());
}

function minutesEqual(minutes, date) {
  minutes = Number(minutes);

  return date && minutes === Number(date.getMinutes());
}

function isDateToday(date) {
  const today = new Date();
  return date.toDateString() === today.toDateString();
}

function isDateInMostRecentDays(date, days) {
  const deltaDate = new Date();
  deltaDate.setDate(deltaDate.getDate() + days);

  if (days > 0) {
    return (date.getTime() < deltaDate.getTime() && date.getTime() > Date.now()) || isDateToday(date);
  }

  if (days < 0) {
    return (date.getTime() > deltaDate.getTime() && date.getTime() < Date.now()) || isDateToday(date);
  }

  return true;
}

function getShortDateString(date) {
  const d = new Date(date);
  let dm = String(d.getMonth());
  let dd = String(d.getDay());
  let dy = String(d.getFullYear()).slice(2);
  return dm + '/' + dd + '/' + dy;
}

function roundMinutesToNearestFive(minutes) {
  return Math.round((minutes * 12) / 60) * 5;
}

export {
  utcStringToLocalDate,
  replaceUtcStringsWithLocalDate,
  getShortDateString,
  datesEqual,
  hoursEqual,
  minutesEqual,
  isDateToday,
  isDateInMostRecentDays,
  roundMinutesToNearestFive,
};

<script>
  import { beforeUpdate } from 'svelte';
  import Ripple from '@smui/ripple';
  import { link } from 'svelte-routing';
  import { NON_BREAKING_SPACE } from '../../util/constants';
  import { utcStringToLocalDate } from '../../util/dates';
  import ClientAppLogo from '../client-apps/ClientAppLogo.svelte';
  import { isUnity, isDeveloper } from '../../util/unity';
  import { clientApps } from '../../stores/client-apps-store';
  import { getAccountId } from '../../util/account';

  export let session;
  $: sessionLessonDate = session && session.lessonDate ? utcStringToLocalDate(session.lessonDate) : null;

  let cardLink = '';
  $: if (session) {
    if (
      (isUnity() && session.createdById !== getAccountId()) ||
      (isUnity() && isDeveloper() && session.createdById === getAccountId())
    ) {
      cardLink = `/sessions/lobby/${session.sessionId}`;
    } else {
      cardLink = `/qr/headset-login/?sessionId=${session.sessionId}`;
    }
  }

  let clientAppName = '';
  let clientAppsById = new Map();
  beforeUpdate(async () => {
    $clientApps.forEach((app) => clientAppsById.set(app.clientAppId, app.clientAppName));

    if (session) {
      if (clientAppsById.has(session.clientAppId)) {
        clientAppName = clientAppsById.get(session.clientAppId);
      }
    }
  });
</script>

<a id="available-session-card-link-{session.sessionId}" href={cardLink} use:link>
  <div class="available-session-card" use:Ripple={{ ripple: true, color: 'surface' }}>
    <div>
      <ClientAppLogo {clientAppName} variant="small" />
    </div>
    <div class="available-session-card__title">{session.sessionName}</div>
    <div class="available-session-card__time">
      {#if sessionLessonDate}
        <span>
          {#if sessionLessonDate > new Date()}UPCOMING{:else}JOIN NOW{/if}
          {sessionLessonDate.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}
        </span>
      {:else}{NON_BREAKING_SPACE}{/if}
    </div>
  </div>
</a>

<style>
  a:hover {
    text-decoration: none;
  }

  .available-session-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    padding: 16px;
    width: 137px;
    text-align: center;
    font-family: 'Barlow', sans-serif;
  }

  .available-session-card > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .available-session-card:hover {
    cursor: pointer;
  }

  .available-session-card__title {
    margin-top: 5px;
    font-weight: 700;
    font-size: 16px;
  }

  .available-session-card__time {
    font-size: 12px;
  }

  .available-session-card__time span {
    font-weight: 700;
  }
</style>

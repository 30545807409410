<script>
  import { onMount } from 'svelte';
  import { authenticatedGet, authenticatedPost } from '../../util/api';
  import { generateQrCodeImageData } from '../../util/qr-codes';
  import { title, errorMessage, breadcrumbPaths } from '../../stores/core-store.js';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import PrimaryContent from '../../components/PrimaryContent.svelte';
  import LoadingView from '../../components/LoadingView.svelte';
  import { getQueryParameter, getMobileOperatingSystem } from '../../util/util';
  import { fetchSession } from '../../util/api/sessions';
  import { isUnity } from '../../util/unity';
  import Button, { Label, Icon } from '@smui/button';
  import { fetchLoginQrCode, sendAppStoreAndSessionEmail } from '../../util/api/accounts';
  import { navigate } from 'svelte-routing';
  import ButtonCircularProgress from '../../components/ButtonCircularProgress.svelte';
  import { fetchFirebaseDynamicLink } from '../../util/api/client-apps';

  title.set('Headset Login');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Headset Login',
      location: '/headset-login',
    },
  ]);

  let loading = true;
  let sessionId = null;
  let session;
  let imageSrc = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
  let emailSending = false;
  let emailSent = false;
  let joinSessionInAppDynamicLink = '';

  const mobileOperatingSystem = getMobileOperatingSystem();

  onMount(async () => {
    let payload = '';
    sessionId = getQueryParameter('sessionId');

    let sessionPromise = Promise.resolve();
    if (sessionId) {
      sessionPromise = fetchSession(sessionId);
      payload = `JOIN SESSION ${sessionId}`;
    }

    let qrCode;
    [qrCode, session] = await Promise.all([fetchLoginQrCode(payload), sessionPromise]);

    imageSrc = await generateQrCodeImageData(String(qrCode));

    if (!isUnity() && (mobileOperatingSystem === 'Android' || mobileOperatingSystem === 'iOS')) {
      joinSessionInAppDynamicLink = await fetchFirebaseDynamicLink(
        session.clientAppId,
        `/sessions/lobby/${session.sessionId}`,
      );
    }

    loading = false;
  });

  async function sendAppStoreAndSessionEmailHandler(event) {
    emailSending = true;
    await sendAppStoreAndSessionEmail(sessionId);
    emailSent = true;
  }
</script>

<PrimaryContent>
  <div class="qr-content">
    {#if session}
      <h3>Join Session via Headset</h3>
    {/if}

    <div class="qr-wrapper">
      {#if loading}
        <LoadingView />
      {:else}<img alt="Login QR Code" src={imageSrc} />{/if}
    </div>

    {#if session}
      <!-- prettier-ignore -->
      <p>
        Scan the QR Code as prompted on your device to join session
        <span class="session-name">{session.sessionName}</span>.
      </p>
    {:else}
      <p>Scan the QR Code as prompted on your device to login.</p>
    {/if}
  </div>
</PrimaryContent>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if !isUnity() && (mobileOperatingSystem === 'Android' || mobileOperatingSystem === 'iOS')}
      <div class="headset-login-mobile-app-section">
        <div class="or-triangle">
          <div class="or-triangle__background-border" />
          <div class="or-triangle__background" />
          <div class="or-triangle__text">Or</div>
        </div>

        <h2>Use the GIGXR Mobile App</h2>

        <Button
          id="join-session-in-app-button"
          variant="unelevated"
          class="gigxr-button"
          on:click={() => navigate(joinSessionInAppDynamicLink)}
        >
          <Label>Join Session in App</Label>
        </Button>

        <p><strong>If you do not have the app installed you will be prompted to download it first.</strong></p>
      </div>
    {/if}

    {#if sessionId}
      <div class="headset-login-desktop-app-section">
        <div class="headset-login-desktop-app-section__left">
          <img src="/assets/gigxr-mobile-phone.png" width="54" height="107" alt="GIGXR logo on mobile phone" />
        </div>
        <div class="headset-login-desktop-app-section__right">
          <p class="headset-login-desktop-app-section__title">
            Using your mobile device to participate in this session?
          </p>
          <p>Download the GIGXR App and follow the instructions in the app to use your phone for GIGXR Sessions.</p>
          {#if emailSent}
            <p>Email sent!</p>
          {:else}
            <div class="gigxr-app-session-link-wrapper">
              <Button
                id="email-app-store-and-session-links-button"
                on:click={sendAppStoreAndSessionEmailHandler}
                class="gigxr-app-session-link"
                disabled={emailSending || emailSent}
              >
                <Label>Email me App Store and Session Links</Label>
                {#if emailSending || emailSent}
                  <ButtonCircularProgress />
                {/if}
              </Button>
            </div>
          {/if}
        </div>
      </div>
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  .session-name {
    font-weight: 700;
    color: var(--gigxr-theme-secondary-2c);
  }

  .or-triangle {
    margin-top: -18px;
    position: relative;
  }

  .or-triangle__background-border {
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 25px solid var(--gigxr-theme-primary-1a);
    width: 0;
    height: 0;
    position: absolute;
    top: -20px;

    left: calc(50% - 25px);
    transform: translateY(-50%);
  }

  .or-triangle__background {
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 25px solid #fff;
    width: 0;
    height: 0;
    position: absolute;
    top: -22px;
    left: calc(50% - 25px);
    transform: translateY(-50%);
  }

  .or-triangle__text {
    position: absolute;
    top: 10px;
    width: 50px;
    left: calc(50% - 25px);
    transform: translateY(-50%);
    margin-top: -42px;
    font-weight: 700;
  }

  .headset-login-mobile-app-section {
    text-align: center;
  }

  .headset-login-mobile-app-section h2 {
    color: var(--gigxr-theme-secondary-2c);
    padding-top: 0.25em;
    font-size: 1.25em;
    margin-top: 20px;
  }

  .gigxr-app-session-link-wrapper {
    position: relative;
  }

  :global(.gigxr-app-button:disabled) {
    color: #000 !important;
    opacity: 0.38;
  }

  .qr-content {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    padding-bottom: 1em;
  }

  h3 {
    margin-top: 0;
  }

  .qr-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 100%;
    margin-bottom: 30px;
    min-height: 300px;
  }

  .qr-wrapper img {
    width: 100%;
  }

  .headset-login-desktop-app-section {
    display: none;
    margin: 1em 0;
  }

  :global(.gigxr-app-session-link) {
    position: relative;
    text-align: left;
    margin-left: -8px;
  }

  :global(.gigxr-app-session-link:not(:disabled)) {
    color: #000 !important;
  }

  :global(.gigxr-app-session-link:disabled) {
    color: rgba(0, 0, 0, 0.38) !important;
  }

  @media (min-width: 1100px) {
    .headset-login-mobile-app-section {
      display: none;
    }

    .headset-login-desktop-app-section {
      display: block;
      display: flex;
      justify-content: space-between;
    }

    .headset-login-desktop-app-section__left {
      margin-left: 2em;
      margin-right: 2em;
      margin-top: 0.5em;
    }

    .headset-login-desktop-app-section__right {
      flex-grow: 1;
      text-align: left;
    }

    .headset-login-desktop-app-section__title {
      font-size: 1.5em;
    }

    .headset-login-desktop-app-section__right p:first-child {
      margin-top: 0;
      margin-bottom: 0.83em;
    }

    .qr-wrapper {
      margin: 0 auto 30px;
      width: 300px;
      height: 300px;
    }

    .qr-wrapper img {
      width: 250px;
    }
  }
</style>

<script>
  import { onMount } from 'svelte';
  import { title, breadcrumbPaths, snackbar, snackbarMessage, isMasquerading } from '../../../stores/core-store';
  import SavedSessionCard from '../../../components/sessions/SavedSessionCard.svelte';
  import FilterComponent from '../../../components/FilterComponent.svelte';
  import { CARD_VIEW_COUNT_PER_PAGE } from '../../../util/constants';
  import PrimaryContent from '../../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../../components/SecondaryBackgroundWrapper.svelte';
  import MobileListPageControls from '../../../components/MobileListPageControls.svelte';
  import ListPageCount from '../../../components/ListPageCount.svelte';
  import FilterButton from '../../../components/FilterButton.svelte';
  import DesktopListPageControls from '../../../components/DesktopListPageControls.svelte';
  import ListPageViewMoreButton from '../../../components/ListPageViewMoreButton.svelte';
  import LoadingView from '../../../components/LoadingView.svelte';
  import { fetchClasses } from '../../../util/api/classes';
  import { fetchVersions } from '../../../util/api/versions';
  import { fetchSessionPlans,  fetchGigXrSessionPlans, patchSessionPlans } from '../../../util/api/sessions';
  import {
    SessionPlanFilterByClassSet,
    SessionPlanFilterByClientAppSet,
    SessionPlanFilterIncludeVersionsSet,
    SessionPlanFilterIncludeArchivedSet,
    SessionPlanSortBySet,
    SessionPlanFilterIncludeSharedSessionPlansSet,
  } from '../../../util/filters/session-plan-list-filters';
  import { fetchClientApps } from '../../../util/api/client-apps';
  import ListPageNoResultsMessage from '../../../components/ListPageNoResultsMessage.svelte';
  import ListPageSearch from '../../../components/ListPageSearch.svelte';
  import ListPageBatchSelect from '../../../components/ListPageBatchSelect.svelte';
  import ListPageActionControls from '../../../components/ListPageActionControls.svelte';
  import ListPageSelectionText from '../../../components/ListPageSelectionText.svelte';
  import ListPageCardGridHeader from '../../../components/ListPageCardGridHeader.svelte';
  import ListPageCardGridItem from '../../../components/ListPageCardGridItem.svelte';
  import ListPageCardGridCheckbox from '../../../components/ListPageCardGridCheckbox.svelte';
  import { getAccountRole } from '../../../util/account';

  title.set('Session Plan List');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Session Plan List',
      location: '/sessions/plans/list',
    },
  ]);

  let loading = true;
  let sessionMenu;
  let filterComponent;
  let filteredSessionPlans = [];
  let sessionPlans = [];
  let filterBySets = [];
  let filteredAndSearchedSessionPlans = [];

  // Batch select
  let showBatchActions = false;
  let selectedValues = [];
  let allSelectedIncludingNotShown = false;
  let listPageActionControlsConfig = {};

  let isGigXrAdmin = getAccountRole() === 'GigXrAdmin' && !isMasquerading;

  let displayedItems = CARD_VIEW_COUNT_PER_PAGE;
  $: remainingItems = filteredAndSearchedSessionPlans.length - displayedItems;

  onMount(async () => {
    let versions;
    let classes;
    let clientApps;
    // weird Svelte way of saying what admin can do versus other people.
    if (isGigXrAdmin) {
      [sessionPlans, classes, versions, clientApps] = await Promise.all([fetchGigXrSessionPlans(true), fetchClasses(), fetchVersions(), fetchClientApps()]);
    } else {
      [sessionPlans, classes, versions, clientApps] = await Promise.all([fetchSessionPlans(true), fetchClasses(), fetchVersions(), fetchClientApps()]);
    }

    if (!isGigXrAdmin) { // for admin session page we want to show all the apps in the filter
      // Only show client app filters for sessions that exist in this data set.
      const clientAppIdsToShow = new Set();
      sessionPlans.forEach((session) => {
        clientAppIdsToShow.add(session.clientAppId);
      });
      clientApps = clientApps.filter((app) => clientAppIdsToShow.has(app.clientAppId));
    }

    filterBySets = [
      SessionPlanFilterByClassSet(classes),
      SessionPlanFilterByClientAppSet(clientApps),
      SessionPlanFilterIncludeVersionsSet(versions),
      SessionPlanFilterIncludeArchivedSet(),
      SessionPlanFilterIncludeSharedSessionPlansSet(isGigXrAdmin),
    ];

    initializeListPageActionControlsConfig();

    loading = false;
  });

  // Real selected values takes into account options like choosing to select all hidden rows, too.
  let realSelectedSessionPlans = [];
  $: {
    const realSelectedValues = selectedValues;
    const totalCount = filteredAndSearchedSessionPlans.length;
    const shownCount = Math.min(displayedItems, filteredAndSearchedSessionPlans.length);

    if (totalCount > shownCount && realSelectedValues.length > shownCount && allSelectedIncludingNotShown) {
      // All values are selected AND the option to include hidden rows is checked.
      realSelectedSessionPlans = filteredAndSearchedSessionPlans.map((session) => session.sessionId);
    } else {
      realSelectedSessionPlans = realSelectedValues;
    }
  }

  // This reactive block will clear the mobile batch selections when it is closed.
  $: if (!showBatchActions) {
    selectedValues = [];
  }

  async function bulkSessionPatch(patchToApply) {
    const patches = [];
    const patchesById = new Map();

    const sessionIdsToPatch = realSelectedSessionPlans;

    sessionIdsToPatch.forEach((sessionId) => {
      const patch = {
        sessionId,
        ...patchToApply,
      };
      patches.push(patch);
      patchesById.set(sessionId, patch);
    });

    await patchSessionPlans(patches);

    // Update data on the client side so we don't have to fetch again.
    sessionPlans.forEach((session) => {
      if (patchesById.has(session.sessionId)) {
        Object.assign(session, patchesById.get(session.sessionId));
      }
    });
    filteredAndSearchedSessionPlans = filteredAndSearchedSessionPlans;

    return patches.length;
  }

  function initializeListPageActionControlsConfig() {
    listPageActionControlsConfig = {
      'Make Archived': {
        callback: async () => {
          const count = await bulkSessionPatch({
            sessionStatus: 'Archived',
          });
          snackbarMessage.set(`${count} Session plan${count === 1 ? '' : 's'} archived!`);
          $snackbar.open();
        },
      },
      'Make Unarchived': {
        callback: async () => {
          const count = await bulkSessionPatch({
            sessionStatus: 'Ended',
          });
          snackbarMessage.set(`${count} Session plan${count === 1 ? '' : 's'} unarchived!`);
          $snackbar.open();
        },
      },
    };
  }

  const searchValueFunctions = {
    sessionName: (session) => session.sessionName,
  };

  // This reactive block controls the header checkbox.
  $: {
    const headerCheckbox = document.getElementById('checkbox-header');
    if (headerCheckbox) {
      if (selectedValues.length >= Math.min(displayedItems, filteredAndSearchedSessionPlans.length)) {
        headerCheckbox.checked = true;
        headerCheckbox.indeterminate = false;
      } else if (selectedValues.length === 0) {
        headerCheckbox.checked = false;
        headerCheckbox.indeterminate = false;
      } else {
        headerCheckbox.checked = false;
        headerCheckbox.indeterminate = true;
      }
    }
  }
</script>

<div style="display: none;">
  <!-- This page doesn't support search yet, but this is wired up here for future support. -->
  <ListPageSearch
    unfilteredList={filteredSessionPlans}
    bind:filteredList={filteredAndSearchedSessionPlans}
    valueFunctions={searchValueFunctions}
  />
</div>

<PrimaryContent>
  <MobileListPageControls>
    <div slot="left">
      <ListPageBatchSelect on:click={() => (showBatchActions = !showBatchActions)} />
      <ListPageCount
        {loading}
        count={filteredAndSearchedSessionPlans.length}
        singularLabel="Session Plan"
        pluralLabel="Session Plans"
      />
    </div>
    <div slot="right">
      <FilterButton on:click={filterComponent.toggle} disabled={showBatchActions} />
    </div>
  </MobileListPageControls>

  <DesktopListPageControls>
    <div slot="left">
      <ListPageBatchSelect on:click={() => (showBatchActions = !showBatchActions)} />
      <ListPageCount
        {loading}
        count={filteredAndSearchedSessionPlans.length}
        singularLabel="Session Plan"
        pluralLabel="Session Plans"
      />
      <FilterButton on:click={filterComponent.toggle} disabled={showBatchActions} />
    </div>
    <div slot="right" />
  </DesktopListPageControls>
</PrimaryContent>

<FilterComponent
  bind:this={filterComponent}
  unfilteredList={sessionPlans}
  bind:filteredList={filteredSessionPlans}
  sortBySet={SessionPlanSortBySet()}
  {filterBySets}
/>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if loading}
      <LoadingView />
    {:else}
      {#if showBatchActions}
        <ListPageActionControls actions={listPageActionControlsConfig} selectedCount={selectedValues.length} />

        <ListPageSelectionText
          totalCount={filteredAndSearchedSessionPlans.length}
          shownCount={Math.min(displayedItems, filteredAndSearchedSessionPlans.length)}
          selectedCount={selectedValues.length}
          singularName="Session Plan"
          pluralName="Session Plans"
          bind:allSelectedIncludingNotShown
        />
      {/if}

      {#if filteredAndSearchedSessionPlans.length === 0}
        <ListPageNoResultsMessage>
          <h3>There are no session plans to display.</h3>
        </ListPageNoResultsMessage>
      {:else}
        <div class="session-list">
          <ListPageCardGridItem hide={!showBatchActions}>
            <ListPageCardGridHeader
              on:change={(event) => {
                if (event.target.checked) {
                  selectedValues = filteredAndSearchedSessionPlans
                    .slice(0, displayedItems)
                    .map((session) => session.sessionId);
                } else {
                  selectedValues = [];
                }
              }}
              compact={true}
            />
          </ListPageCardGridItem>
          {#each filteredAndSearchedSessionPlans as session, index (session.sessionId)}
            {#if index < displayedItems}
              <ListPageCardGridItem>
                <ListPageCardGridCheckbox
                  value={session.sessionId}
                  bind:group={selectedValues}
                  show={showBatchActions}
                />
                <SavedSessionCard {session} compact={showBatchActions} bind:selectedValues />
              </ListPageCardGridItem>
            {/if}
          {/each}
        </div>
      {/if}

      {#if remainingItems > 0}
        <ListPageViewMoreButton
          itemName="Session Plans"
          {remainingItems}
          on:click={() => (displayedItems += CARD_VIEW_COUNT_PER_PAGE)}
        />
      {/if}
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  .session-list {
    display: grid;
    column-gap: 30px;
    justify-items: center;
  }

  @media (min-width: 768px) and (max-width: 1099px) {
    .session-list {
      grid-template-columns: repeat(2, 1fr);
      justify-content: space-between;
    }
  }

  @media (min-width: 1100px) {
    .session-list {
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-between;
    }
  }
</style>

<script>
  import { onMount } from 'svelte';
  import { generateQrCodeImageData } from '../../util/qr-codes';
  import { title, errorMessage, breadcrumbPaths } from '../../stores/core-store';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import PrimaryContent from '../../components/PrimaryContent.svelte';

  title.set('Calibrate Room');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Calibrate Room',
      location: '/calibrate-room',
    },
  ]);

  let canvas;
  let imageSrc = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

  onMount(async () => {
    imageSrc = await generateQrCodeImageData('GIGXR');
  });
</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    <div class="qr-content">
      <div class="qr-wrapper"><img alt="Login QR Code" src={imageSrc} /></div>

      <h2>Calibration Instructions</h2>

      <p>
        Calibrating all devices for the space you are working in will ensure all participants will see Session content
        in the same place and orientation. Users joining your Session remotely do not need to calibrate.
      </p>

      <ol>
        <li>
          Print or place the Calibration QR Code where all participants, including the host, can easily scan it with
          their device.
        </li>
        <li>All participants in a Session must then scan the QR Code at the same position in the room.</li>
      </ol>

      <p>
        Your Content Placeholder will appear at the point where you placed your QR Code. The Host of your Session can
        then move this Content Placeholder to a more appropriate position to instantiate the Content.
      </p>
    </div>
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  p {
    text-align: left;
  }

  li {
    text-align: left;
  }

  .qr-content {
    text-align: center;
    margin: auto;
    width: 100%;
  }

  .qr-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 100%;
    margin-bottom: 30px;
  }

  .qr-wrapper img {
    width: 100%;
  }

  @media (min-width: 1100px) {
    .qr-wrapper {
      margin: 0 auto 30px;
      width: 300px;
      height: 300px;
    }

    .qr-wrapper img {
      width: 250px;
    }
  }
</style>

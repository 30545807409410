<script>
  import Button, { Label, Icon } from '@smui/button';
  import Card, { Content, PrimaryAction, Media, MediaContent, Actions, ActionButtons, ActionIcons } from '@smui/card';
  import IconButton from '@smui/icon-button';
  import { onDestroy, onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import {
    subscribeToUnityMessage,
    getResourceIdsForSession,
    sendUnityGetDownloadsMessage,
    sendUnityDownloadResourcesForSessionMessage,
    sendUnityStopDownloadMessage,
  } from '../../util/unity';
  import { getMobileOperatingSystem } from '../../util/util';
  import BottomModal, { showBottomModal } from '../BottomModal.svelte';
  import GigXrDialog from '../gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogActions from '../gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogButton from '../gigxr-dialog/GigXrDialogButton.svelte';
  import GigXrDialogCancelButton from '../gigxr-dialog/GigXrDialogCancelButton.svelte';
  import GigXrDialogContent from '../gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogIcon from '../gigxr-dialog/GigXrDialogIcon.svelte';
  import LoadingView from '../LoadingView.svelte';
  import SessionContentManagerProgressCircle from './SessionContentManagerProgressCircle.svelte';
  import SessionContentManagerProgressCircleContainer from './SessionContentManagerProgressCircleContainer.svelte';

  const MAX_PROGRESS_CIRCLES_PER_ROW = 10;

  export let session;

  /** @type {'loading', 'noDownloads', 'needsDownloads', 'allDownloaded'} */
  let state = 'loading';

  let unsubscribeFunctions = [];
  let downloadListLoaded = false;
  let evictNeededResourcesDialog;

  let resourceIds = [];
  let resourcesById = new Map();

  onMount(async () => {
    unsubscribeFunctions = [
      subscribeToUnityMessage(UnityAction.GET_DOWNLOADS_RESPONSE, (payload) => {
        resourceIds = getResourceIdsForSession(session);

        const map = new Map();
        const resources = payload;
        resources.forEach((resource) => {
          map.set(resource.resourceId, resource);
        });
        resourcesById = map;
        downloadListLoaded = true;
      }),
      subscribeToUnityMessage(UnityAction.DOWNLOAD_SET_FAILED_INSUFFICIENT_STORAGE, () => {
        showBottomModal();
      }),
      subscribeToUnityMessage(UnityAction.CANNOT_EVICT_NEEDED_RESOURCES, () => {
        if (evictNeededResourcesDialog.isOpen()) {
          return;
        }

        evictNeededResourcesDialog.open();
      }),
    ];

    pollForDownloads();
  });

  let pollForDownloadsTimeout;
  function pollForDownloads() {
    sendUnityGetDownloadsMessage();

    pollForDownloadsTimeout = setTimeout(() => {
      pollForDownloads();
    }, 1000);
  }

  let toDownloadCount = 0;
  let downloadingCount = 0;

  // This reactive block compares the resources needed for a session with the resources available on the device.
  $: if (downloadListLoaded) {
    let _toDownloadCount = 0;
    let _downloadingCount = 0;

    for (let i = 0; i < resourceIds.length; i++) {
      if (!resourcesById.has(resourceIds[i])) {
        _toDownloadCount++;
        continue;
      }
      if (resourcesById.get(resourceIds[i]).progress < 1.0) {
        _downloadingCount++;
      }
    }

    toDownloadCount = _toDownloadCount;
    downloadingCount = _downloadingCount;

    if (resourceIds.length === 0) {
      state = 'noDownloads';
    } else if (toDownloadCount === 0 && downloadingCount === 0) {
      state = 'allDownloaded';
    } else {
      state = 'needsDownloads';
    }
  }

  function downloadHandler() {
    sendUnityDownloadResourcesForSessionMessage(session);
  }

  function downloadWithEvictionHandler() {
    sendUnityDownloadResourcesForSessionMessage(session, true);
  }

  function stopDownloadsHandler() {
    for (const resourceId of resourceIds) {
      sendUnityStopDownloadMessage(resourceId);
    }
  }

  onDestroy(() => {
    clearTimeout(pollForDownloadsTimeout);
    unsubscribeFunctions.forEach((fn) => fn());
  });
</script>

<Card class="session-content-manager">
  <Content class="session-content-manager__content">
    <div class="session-content-manager__title">Session Content</div>
    <div class="session-content-manager__settings">
      <IconButton id="app-settings-shortcut-button" class="material-icons" on:click={() => navigate('/app-settings')}>
        miscellaneous_services
      </IconButton>
    </div>
    {#if state === 'needsDownloads'}
      <div class="session-content-manager__text">
        {#if toDownloadCount === 0}
          {#if downloadingCount === 1}
            {downloadingCount}
            resource downloading
          {:else}{downloadingCount} resources downloading{/if}
        {:else if toDownloadCount === 1}
          {toDownloadCount}
          resource needs to be downloaded
        {:else}{toDownloadCount} resources need to be downloaded{/if}
      </div>
      <div class="session-content-manager__progress">
        <SessionContentManagerProgressCircleContainer>
          {#each resourceIds as resourceId, index (index)}
            {#if index % MAX_PROGRESS_CIRCLES_PER_ROW === 0}
              <div class="flex-break" />
            {/if}
            <SessionContentManagerProgressCircle
              number={index + 1}
              progress={resourcesById.has(resourceId) ? resourcesById.get(resourceId).progress : 0.0}
            />
          {/each}
        </SessionContentManagerProgressCircleContainer>
      </div>
      <div class="session-content-manager__button">
        {#if toDownloadCount > 0}
          <Button class="gigxr-button gigxr-button--large" variant="unelevated" on:click={downloadHandler}>
            <Icon class="material-icons">save_alt</Icon>
            <Label>Download</Label>
          </Button>
        {:else if getMobileOperatingSystem() !== 'iOS'}
          <Button class="gigxr-button gigxr-button--large" variant="outlined" on:click={stopDownloadsHandler}>
            <Label>Stop Download</Label>
          </Button>
        {/if}
      </div>
    {:else if state === 'noDownloads'}
      <div class="session-content-manager__text">No resources have been added to this session</div>
      <div class="session-content-manager__download-complete">
        <Icon class="material-icons">tv_off</Icon>
      </div>
    {:else if state === 'allDownloaded'}
      <div class="session-content-manager__text">All resources downloaded</div>
      <div class="session-content-manager__download-complete">
        <Icon class="material-icons">check_circle_outline</Icon>
      </div>
    {:else}
      <LoadingView />
    {/if}
  </Content>
</Card>

<BottomModal buttonText="OK">
  <p>
    There is insufficient storage on this device to download resources for this session. Please free up some storage and
    try again.
  </p>
</BottomModal>

<GigXrDialog bind:dialog={evictNeededResourcesDialog} ariaPrefix="evict-needed-resources">
  <GigXrDialogContent>
    <GigXrDialogIcon />
    <p>Downloading resources for this session will delete resources that are needed for future sessions.</p>
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton>Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton on:click={downloadWithEvictionHandler}>Continue Download</GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<style>
  :global(.session-content-manager) {
    font-family: 'Barlow', sans-serif;
    border-radius: 10px !important;
    margin-bottom: 30px;
  }

  .session-content-manager__title {
    font-size: 1.17em;
    font-weight: 700;
    margin-bottom: 14px;
  }

  :global(.session-content-manager__content) {
    position: relative;
  }

  .session-content-manager__settings {
    position: absolute;
    top: 2px;
    right: 2px;
  }

  .session-content-manager__text {
    text-align: center;
  }

  .session-content-manager__progress {
    min-height: 30px;
    padding: 12px 20px 10px;
  }

  .session-content-manager__button {
    text-align: center;
  }

  .session-content-manager__download-complete {
    text-align: center;
  }

  .session-content-manager__download-complete :global(.material-icons) {
    color: var(--gigxr-theme-secondary-2a);
    font-size: 48px;
    margin-top: 16px;
  }

  .flex-break {
    flex-basis: 100%;
    height: 0;
  }
</style>

import * as devConfig from './configs/config.dev';
import * as gmsteConfig from './configs/config.gmste';
import * as gmsqaConfig from './configs/config.gmsqa';
import * as prodConfig from './configs/config.prod';

// This needs to be a global so `terser` will not collapse this variable.
// https://github.com/TrySound/rollup-plugin-terser/issues/43
window.GMS_ENVIRONMENT = '$GMS_ENVIRONMENT';
window.GMS_CONFIG = '$GMS_CONFIG';

console.debug(`Initializing GMS: ${window.GMS_ENVIRONMENT}`);

// Default values
let gmsConfig = {
  BASE_API_URL: 'http://localhost:5001',
  DEVELOPMENT: true,
  GA_MEASUREMENT_ID: '',

  /** @deprecated For HoloPatient 2.0, will be removed in the future. */
  FIREBASE_DYNAMIC_LINK_URL: 'https://gigmobile.page.link',

  /** @deprecated For HoloPatient 2.0, will be removed in the future. */
  ANDROID_PACKAGE_NAME: 'com.gigxr.gigmobile',

  APPLICATION_INSIGHTS_INSTRUMENTATION_KEY: '',
  SENTRY_ENVIRONMENT: 'dev',
};

switch (window.GMS_ENVIRONMENT) {
  case 'prod':
    gmsConfig = {
      ...gmsConfig,
      ...prodConfig,
    };
    break;

  case 'gmsqa':
    gmsConfig = {
      ...gmsConfig,
      ...gmsqaConfig,
    };
    break;

  case 'gmste':
    gmsConfig = {
      ...gmsConfig,
      ...gmsteConfig,
    };
    break;

  case 'dev':
    gmsConfig = {
      ...gmsConfig,
      ...devConfig,
    };
    break;
  case 'dyn':
    gmsConfig = {
      ...gmsConfig,
      ...(JSON.parse(window.GMS_CONFIG))
    }
    break;

  default:
    throw new Error('GMS_ENVIRONMENT environment variable is not set!');
}

export const BASE_API_URL = gmsConfig.BASE_API_URL;
export const DEVELOPMENT = gmsConfig.DEVELOPMENT;
export const GA_MEASUREMENT_ID = gmsConfig.GA_MEASUREMENT_ID;
export const FIREBASE_DYNAMIC_LINK_URL = gmsConfig.FIREBASE_DYNAMIC_LINK_URL;
export const ANDROID_PACKAGE_NAME = gmsConfig.ANDROID_PACKAGE_NAME;
export const APPLICATION_INSIGHTS_INSTRUMENTATION_KEY = gmsConfig.APPLICATION_INSIGHTS_INSTRUMENTATION_KEY;
export const SENTRY_ENVIRONMENT = gmsConfig.SENTRY_ENVIRONMENT;

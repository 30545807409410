<script>
  import { navigate } from 'svelte-routing';
  import List, { Item, Text, Graphic } from '@smui/list';
  import IconButton, { Icon } from '@smui/icon-button';
  import { authorizedRoles as userRoles } from '../routes/users/List.svelte';
  import { authorizedRoles as classRoles } from '../routes/classes/List.svelte';
  import { authorizedRoles as departmentRoles } from '../routes/departments/List.svelte';
  import { authorizedRoles as licenseRoles } from '../routes/licenses/List.svelte';
  import { AccountRoleText } from '../util/api/accounts';
  import { fade } from 'svelte/transition';
  import { AccountRole } from '../util/api/accounts';
  import { openLogoutDialog } from '../components/my-account/LogoutDialog.svelte';
  import { role, institutionMasquerade, isMasquerading } from '../stores/core-store';

  let expanded = false;

  function toggleDesktopMenu() {
    expanded = !expanded;
  }

  function menuAction(linkTo) {
    navigate(linkTo);
    expanded = false;
  }

  let scrollY = 0;
  $: if (document) {
    if (expanded) {
      scrollY = window.scrollY;
      document.body.classList.add('no-scroll');
      document.body.style.top = `-${scrollY}px`;
    } else {
      document.body.classList.remove('no-scroll');
      window.scroll(0, scrollY);
    }
  }
</script>

{#if expanded}
  <div
    id="desktop-nav-clickable-background-element"
    class="desktop-side-nav-faded-background"
    transition:fade={{ duration: 200 }}
    on:click={toggleDesktopMenu}
  />
{/if}

<nav
  class="desktop-side-nav {expanded ? 'desktop-side-nav-expanded' : ''}
    {$isMasquerading ? 'desktop-side-nav--with-masquerade' : ''}"
>
  <div class="desktop-menu-wrapper">
    <IconButton id="desktop-nav-button" class="material-icons" on:click={toggleDesktopMenu}>menu</IconButton>
  </div>

  <List class="desktop-side-nav__list">
    {#if $role === AccountRole.GIGXR_ADMIN}
      <Item id="desktop-nav-system-dashboard-link" on:SMUI:action={() => menuAction('/')} title="System Dashboard">
        <Graphic class="material-icons">view_module</Graphic>
        <Text>System Dashboard</Text>
      </Item>
      <!--<Item id="desktop-nav-collections-link" on:SMUI:action={() => menuAction('/assets')} title="Asset Management">
        <Graphic class="material-icons">collections</Graphic>
        <Text>Asset Management</Text>
      </Item>-->
      <Item id="desktop-nav-modules-link" on:SMUI:action={() => menuAction('/modules')} title="Modules">
        <Graphic class="material-icons">schema</Graphic>
        <Text>Modules</Text>
      </Item>
      <Item
        id="desktop-nav-session-plans-link"
        on:SMUI:action={() => menuAction('/sessions/plans/list')}
        title="Session Plans"
      >
        <Graphic class="material-icons">content_paste</Graphic>
        <Text>Session Plans</Text>
      </Item>
    {:else}
      <Item id="desktop-nav-dashboard-link" on:SMUI:action={() => menuAction('/')} title="Dashboard">
        <Graphic class="material-icons">view_module</Graphic>
        <Text>Dashboard</Text>
      </Item>

      <Item id="desktop-nav-sessions-link" on:SMUI:action={() => menuAction('/sessions/list')} title="Saved Sessions">
        <Graphic class="material-icons">save</Graphic>
        <Text>Saved Sessions</Text>
      </Item>

      <Item
        id="desktop-nav-session-plans-link"
        on:SMUI:action={() => menuAction('/sessions/plans/list')}
        title="Session Plans"
      >
        <Graphic class="material-icons">content_paste</Graphic>
        <Text>Session Plans</Text>
      </Item>

      {#if userRoles.includes($role)}
        <Item id="desktop-nav-users-link" on:SMUI:action={() => menuAction('/users/list')} title="User Management">
          <Graphic class="material-icons">people</Graphic>
          <Text>User Management</Text>
        </Item>
      {/if}

      {#if classRoles.includes($role)}
        <Item id="desktop-nav-classes-link" on:SMUI:action={() => menuAction('/classes/list')} title="Classes">
          <Graphic class="material-icons">calendar_today</Graphic>
          <Text>Classes</Text>
        </Item>
      {/if}

      {#if departmentRoles.includes($role)}
        <Item
          id="desktop-nav-departments-link"
          on:SMUI:action={() => menuAction('/departments/list')}
          title="Departments"
        >
          <Graphic class="material-icons">account_tree</Graphic>
          <Text>Departments</Text>
        </Item>
      {/if}

      {#if licenseRoles.includes($role)}
        <Item id="desktop-nav-licenses-link" on:SMUI:action={() => menuAction('/licenses/list')} title="Licenses">
          <Graphic class="material-icons">vpn_key</Graphic>
          <Text>Licenses</Text>
        </Item>
      {/if}

      <Item
        id="desktop-nav-calibrate-room-link"
        on:SMUI:action={() => menuAction('/qr/calibrate-room')}
        title="Calibrate Room"
      >
        <Graphic class="material-icons">gps_fixed</Graphic>
        <Text>Calibrate Room</Text>
      </Item>
    {/if}

    <Item id="desktop-nav-my-account-link" on:SMUI:action={() => menuAction('/my-account/view')} title="My Account">
      <Graphic class="material-icons">person</Graphic>
      <Text>My Account</Text>
    </Item>


    {#if $role !== AccountRole.GIGXR_ADMIN}
      <Item id="desktop-nav-support-link" on:SMUI:action={() => menuAction('/support')} title="Support">
        <Graphic class="material-icons">help_outline</Graphic>
        <Text>Support</Text>
      </Item>
    {/if}

    <Item desktop-nav-logout-link on:SMUI:action={() => openLogoutDialog()} title="Logout">
      <Graphic class="material-icons">power_settings_new</Graphic>
      <Text>Logout</Text>
    </Item>
  </List>
</nav>

<style>
  :global(.desktop-side-nav span) {
    color: #fff;
  }

  .desktop-menu-wrapper {
    padding-left: 5px;
    /* margin-bottom: 25px; */
    margin-bottom: 10px;
  }

  .desktop-side-nav {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    background: var(--gigxr-theme-primary-1a);
    width: 80px;
    height: 100%;
    padding: 10px;
    margin: 0;
    color: #fff;
    box-sizing: border-box;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border: 0;
    transition: box-shadow 0.2s linear, width 0.2s;
    z-index: 11;
    border-radius: 0 12px 12px 0;
  }

  .desktop-side-nav-expanded {
    width: 250px;
  }

  .desktop-side-nav--with-masquerade {
    height: calc(100% - 46px);
    top: 46px;
  }

  .desktop-side-nav-faded-background {
    display: none;
  }

  :global(.desktop-side-nav .mdc-list-item__graphic) {
    pointer-events: none;
  }

  .desktop-side-nav :global(.desktop-side-nav__list) {
    padding-top: 15px;
    overflow-y: auto;
    height: calc(100% - 60px);
    /** Edge */
    -ms-overflow-style: none;
  }

  /** Chrome, Safari, and Opera */
  .desktop-side-nav :global(.desktop-side-nav__list)::-webkit-scrollbar {
    width: 0 !important;
  }

  @media (min-width: 1100px) {
    .desktop-side-nav {
      visibility: visible;
    }

    .desktop-side-nav-faded-background {
      display: block;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background: #000;
      opacity: 0.5;
      z-index: 6;
    }
  }
</style>

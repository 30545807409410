<script>
  import IconButton from '@smui/icon-button';
  import { navigate } from 'svelte-routing';
  import Menu from '@smui/menu';
  import List, { Item, Separator, Text } from '@smui/list';

  export let disabled = false;

  let sessionMenu;
</script>

<div>
  <IconButton
    id="add-session-button"
    title="Add Session"
    class="material-icons gigxr-icon-button add-session-button"
    on:click={() => sessionMenu.setOpen(true)}
    {disabled}
  >
    add_box
  </IconButton>

  <Menu bind:this={sessionMenu} anchorCorner="BOTTOM_LEFT" class="gigxr-menu">
    <List>
      <Item id="add-session-new-link" on:SMUI:action={() => navigate('/sessions/create')}>
        <Text>Create New Session</Text>
      </Item>
      <Item id="add-session-new-plan-link" on:SMUI:action={() => navigate('/sessions/plans/list')}>
        <Text>Use Session Plan</Text>
      </Item>
    </List>
  </Menu>
</div>

<style>
  :global(.add-session-button:disabled) {
    opacity: 0.38;
  }
</style>
